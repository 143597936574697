<template>
    <section class="h-100">
        <div class="row mx-0 p-2 py-2 px-2 border-bottom align-items-center">
            <template>
                <button class="btn-action border mr-2" type="button" @click="imprimir">
                    <i class="icon-printer f-20" />
                </button>
                <p v-show="[4,32,33,34].indexOf(pedido.estado) > -1" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Entregado</p>
                <p v-show="[23,27].indexOf(pedido.estado) > -1" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Cancelado Por Cliente</p>
                <p v-show="pedido.estado === 25" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Rechazado</p>
                <p v-show="[21,22,24,26].indexOf(pedido.estado) > -1" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Cancelado</p>


                <div v-show="pedido.manual === 0" class="d-middle ml-auto">
                    <div v-show="[21,22,24,26].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-cancel-circled-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="[23,27].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-account-remove-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="pedido.estado === 25" class="d-middle border-general px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-playlist-remove" />
                        <p>Rechazado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="[32,33,34].indexOf(pedido.estado) > -1" class="btn-general f-14">
                        <i class="icon-attention-alt f-18 text-general-red" />
                        <p class="mx-2">Reclamo reportado</p>
                        <i class="icon-angle-right f-18" />
                    </div>
                    <el-tooltip v-show="pedido.directo == 1" class="item" effect="light" content="Este pedido fue administrado por el Cedis" placement="bottom">
                        <i class="icon-warehouse f-22 cr-pointer hover-icons mx-2" />
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Hablar con el cliente" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" class="item mx-2 mr-4">
                            <i class="icon-message-text cr-pointer f-20 hover-icons" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                </div>
                <div v-show="pedido.manual === 1" class="d-middle ml-auto">
                    <div class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-account-reactivate" />
                        <p>Manual </p>
                        <p> ({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                </div>
            </template>
        </div>
        <div class="row mx-0 container-cols" style="height:calc(100% - 70px);">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0 h-100 border-right">
                <div class="p-3 f-15 h-100 overflow-auto custom-scroll">
                    <div class="row mx-0 justify-center">
                        <card-producto-pedido-ver
                        v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                        :info="prod"
                        class="cr-pointer"
                        @accion="modalProducto"
                        />
                    </div>

                    <contentLoader v-show="loading" />

                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0 h-100 overflow-auto custom-scroll">
                <div class="row mx-0 border-bottom">
                    <div
                    class="col py-2 pt-0 d-middle-center cr-pointer"
                    :class="tab=='detalle'? 'border-b-red text-general-red':''"
                    @click="tab='detalle'"
                    >
                        Detalle
                    </div>
                    <div
                    v-if="!_.isEmpty(pedido) && pedido.pago_estado != 1"
                    class="col py-2 pt-0 d-middle-center cr-pointer"
                    :class="tab=='pago'? 'border-b-red text-general-red':''"
                    @click="tab='pago'"
                    >
                        Pagos
                    </div>
                </div>
                <detalle-historial v-show="id_pedido && tab === 'detalle'" ref="tabDetalle" :pedido="pedido" />
                <pago v-show="tab === 'pago'" :pedido="pedido" :vista="3" @anular="eventoAnular" />
            </div>
        </div>

        <modalDetalleProd ref="modalDetalleProd" />
        <modalProductoDesconocido ref="modalProductoDesconocido" />
        <modalChat ref="modalChat" :pedido="pedido" :cliente="cliente" />
    </section>
</template>

<script>
import PdfPedidos from '~/services/pdf/pdf'
import {mapGetters} from 'vuex'
export default {
    components: {
        detalleHistorial: () => import('./detalleHistorial'),
        modalDetalleProd: () => import('../partials/modalDetalleProducto'),
        modalProductoDesconocido: () => import('../partials/modalProductoDesconocido'),
        cardProductoPedidoVer: () => import('../partials/card-producto-pedido-ver'),
        pago: () => import('~/pages/almacen/tendero/historial/pago.vue'),
    },
    data(){
        return {
            tab:'detalle',
            editar: false,
            verDetalle: 0,
            estadoPedido: { estado: 'Reportado', icono: 'icon-cancel-circled-outline' },
            loading:false

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial/id_pedido',
            productos: 'pedidos/pedidos_historial/productos',
            pedido: 'pedidos/pedidos_historial/pedido',
            cliente: 'pedidos/pedidos_historial/cliente',
        }),
    },
    watch:{
        id_pedido(val){
            if(val){
                this.listar_productos()
            }else{
                this.$store.dispatch('pedidos/pedidos_historial/set_data')
            }
        }
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos_historial/pedidos_productos',this.id_pedido)
                await this.$store.dispatch('pedidos/pedidos_historial/get_datos',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial/pedidos_historial',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial/pedidos_resumenes', this.id_pedido)
                if(this.pedido.pago_estado !== 1){
                    this.$store.dispatch('pedidos/pedidos_historial/pedido_credito', this.id_pedido)
                }
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        modalProducto(prod){
            // console.log(prod);
            if(prod.estado === 3){
                this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                return
            }
            this.$refs.modalDetalleProd.datos_cambio(prod) // modal detalle producto
        },
        verChat(){
            let soloVisual = true
            this.$refs.modalChat.toggle(this.id_pedido, 2, soloVisual) // 2 = tipo pedido
        },
        async imprimir(){
            try {
                const {data} = await PdfPedidos.generar_pdf_ticket_pedidos_cliente({id_pedido: this.id_pedido})
                const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                const pdfWindow = window.open(url);
                pdfWindow.print();


            } catch (e){
                this.error_catch(e)
            }
        },
        eventoAnular(){
            this.$refs.tabDetalle.anularCredito()
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
@media(max-width:1000px){
    .container-cols{
        height: calc(100vh - 205px) !important;
    }
}
</style>
